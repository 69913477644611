<template>
    <div v-if="list" :class="['t-select']" >
        <t-text-field
                :class="[{'is-list-show' : isListShowing}]"
                :label="placeholder"
                readonly
                is-select-component
                v-model="selected"
                @blur="hideList()"
                @onAppend="showList()"
                @click="showList()"
        >
            <template slot="append-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 15.713L18.01 9.70302L16.597 8.28802L12 12.888L7.40399 8.28802L5.98999 9.70202L12 15.713Z" fill="#586770"/>
                </svg>
            </template>
        </t-text-field>
        <div :class="['t-select__options', {'list-show' : isListShowing}]">
            <p v-for="option in list" :key="option.value" class="t-select__options-option subtitle-2" @mousedown="select(option)">
                {{option[textValue]}}
            </p>
        </div>
    </div>
</template>

<script>
    import TTextField from "./TTextField";
    export default {
        name: "TSelect",
        components: {TTextField},
        props: {
            // a list of options objects {name, value}
            list: {
                type: Array,
                required: true
            },
            // show the default value from list
            selectedValue: {
                type: [String, Number, Object],
                default: undefined
            },
            placeholder: {
                type: String,
                default: ""
            },
            textValue: {
                type: String,
                default: "name"
            }
        },
        computed: {
            disabled() {
                return this.$attrs.disabled
            },
            selected: {
                get() {
                    let val = null
                    if (typeof(this.selectedValue) === "number") {
                        val = this.selectedValue > 0 && this.list[this.selectedValue-1] ? this.list[this.selectedValue-1][this.textValue] : ''
                    }
                    if (typeof(this.selectedValue) === "string") {
                        let obj = this.list[this.selectedValue]
                        val = obj[this.textValue]
                    }
                    if (typeof(this.selectedValue) === "object") {
                        val = this.selectedValue[this.textValue]
                    }
                    return val
                },
                set(newVal) {
                    this.$emit('selected', newVal)
                }
          }
        },
        data() {
            return {
                isListShowing: false
            }
        },
        methods: {
            select(selected) {
                this.selected = selected
                this.isListShowing = false
            },
            showList() {
                if (!this.$attrs.disabled) this.isListShowing = true
            },
            hideList() {
                this.isListShowing = false
            }
        },
    }
</script>
<style scoped lang="scss">
    .t-select {
        position: relative;
        border: 2px solid #5b5c67;
        box-sizing: border-box;
        border-radius: 8px;

        &__options {
            display: none;
            position: absolute;
            padding: 10px 0 15px 0;
            left: -2px;
            right: -2px;
            top: calc(100% - 4px);
            border: 2px solid $grayscale-extra-light;
            border-radius: 0 0 8px 8px;
            border-top: none;
            background-color: $grayscale-middle;
            z-index: 50;
            max-height: 250px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 0px;
            }
            &.list-show {
                display: block;
            }
            &-option {
                color: $white;
                margin: 0;
                padding: 13px 12px;
                box-sizing: border-box;
                transition: background-color .3s, color .2s;
                cursor: default;
                &:hover {
                    background-color: $grayscale-light;
                    //color: $RWM-white;
                }
            }
        }

        &[disabled] {
            border: 2px solid rgba(91, 92, 103, 0.3);
        }
    }
</style>
<style lang="scss">
    .t-select {
        & input {
            cursor: pointer;
        }
    }
</style>
