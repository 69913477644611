<template>
  <div class="app-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AppContent",
}
</script>

<style scoped lang="scss">
.app-content {
  flex: 1 1 auto;
  height: inherit;
  padding: 0 27px;
  background-color: $grayscale-dark;
  overflow: hidden;
  position: relative;
}
</style>
