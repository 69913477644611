<template>
    <div class="modal-wrapper">
        <rnd-btn class="close-btn" @click="$modal.hide(modalName)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="white"/>
            </svg>
        </rnd-btn>
        <slot />
    </div>
</template>
<script>
    import RndBtn from "../Blocks/RndBtn/RndBtn";
    export default {
        name: 'ModalWrapper',
        components: {RndBtn},
        props: {
            modalName: {
                type: String,
            }
        }
    }
</script>

<style lang="scss">
    .modal-wrapper {
        position: relative;
        font-family: Geometria;
        font-style: normal;
        & .close-btn {
            position: absolute;
            right: 12px;
            top: 12px;
            z-index: 10;
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before {
                content: "";
                left: 0;
                top: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 50%;
                background-color: $grayscale-light;
                transition: all 300ms;
                transform: scale(0);
                opacity: 0.5;
            }
            & svg {
                position: absolute;
                z-index: 20;
            }
            &:hover:before {
                transform: scale(1);
            }
        }
    }
    .vm--overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }
    .vm--modal {
        box-sizing: border-box;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        background-color: $grayscale-dark;
        border-radius: 18px;
    }
</style>