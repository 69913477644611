<template>
    <modal-wrapper :modal-name="modalName">
        <section class="calcModal">
            <h3 class="modal-title">Калькулятор</h3>
            <div class="calcModal-content">
                <div class="calcModal-content__history">
                    <div class="calcModal-content__history-search">
                        <fined-field v-model="searching"/>
                    </div>
                    <div class="calcModal-content__history-list" @scroll="onScroll">
                        <calc-history-item
                                v-for="item in filteredHistory"
                                :key="item.id"
                                :id="item.id"
                                :date="item.stamp"
                                :symbol="item.symbol"
                                :transaction-type="item.type"
                                @click="openCalculate"
                        />
                    </div>
                </div>
                <div class="calcModal-content__modal-body" ref="calculator">
                    <div class="calcModal-content__modal-body-header">
                        <div class="modal-btn-group">
                            <rnd-btn class="rnd-btn" @click="clear()">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 6.29272V11.0935H8.80079" stroke="white" stroke-width="1.60026"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.00833 15.0941C6.52713 16.5667 7.51045 17.8307 8.81012 18.6958C10.1098 19.5609 11.6554 19.9802 13.2141 19.8905C14.7728 19.8008 16.2601 19.2069 17.4519 18.1984C18.6438 17.1899 19.4756 15.8214 19.822 14.2991C20.1684 12.7767 20.0107 11.183 19.3726 9.75812C18.7346 8.3332 17.6507 7.15425 16.2843 6.3989C14.9179 5.64355 13.3431 5.35272 11.797 5.57024C10.251 5.78776 8.81752 6.50184 7.71261 7.60488L4 11.0935"
                                          stroke="white" stroke-width="1.60026" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </rnd-btn>
                            <rnd-btn @click="onScreenShot" :load="doingScreenshot">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.2222 4.5H5.77778C4.79594 4.5 4 5.29594 4 6.27778V18.7222C4 19.7041 4.79594 20.5 5.77778 20.5H18.2222C19.2041 20.5 20 19.7041 20 18.7222V6.27778C20 5.29594 19.2041 4.5 18.2222 4.5Z"
                                          stroke="white" stroke-width="1.77778" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                    <path d="M20.0001 15.1666L15.5556 10.7222L5.77783 20.4999" stroke="white"
                                          stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.889 10.7223C9.62538 10.7223 10.2223 10.1254 10.2223 9.389C10.2223 8.65262 9.62538 8.05566 8.889 8.05566C8.15262 8.05566 7.55566 8.65262 7.55566 9.389C7.55566 10.1254 8.15262 10.7223 8.889 10.7223Z"
                                          stroke="white" stroke-width="1.77778" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </rnd-btn>
                            <rnd-btn :disabled="!CAN_SAVE" @click="saveCalculate">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.71 9.79L14.71 3.79C14.6178 3.70005 14.5092 3.62874 14.39 3.58C14.266 3.52962 14.1338 3.5025 14 3.5H6C5.20435 3.5 4.44129 3.81607 3.87868 4.37868C3.31607 4.94129 3 5.70435 3 6.5V18.5C3 19.2956 3.31607 20.0587 3.87868 20.6213C4.44129 21.1839 5.20435 21.5 6 21.5H18C18.7956 21.5 19.5587 21.1839 20.1213 20.6213C20.6839 20.0587 21 19.2956 21 18.5V10.5C21.0008 10.3684 20.9755 10.2379 20.9258 10.1161C20.876 9.99426 20.8027 9.88344 20.71 9.79ZM9 5.5H13V7.5H9V5.5ZM15 19.5H9V16.5C9 16.2348 9.10536 15.9804 9.29289 15.7929C9.48043 15.6054 9.73478 15.5 10 15.5H14C14.2652 15.5 14.5196 15.6054 14.7071 15.7929C14.8946 15.9804 15 16.2348 15 16.5V19.5ZM19 18.5C19 18.7652 18.8946 19.0196 18.7071 19.2071C18.5196 19.3946 18.2652 19.5 18 19.5H17V16.5C17 15.7044 16.6839 14.9413 16.1213 14.3787C15.5587 13.8161 14.7956 13.5 14 13.5H10C9.20435 13.5 8.44129 13.8161 7.87868 14.3787C7.31607 14.9413 7 15.7044 7 16.5V19.5H6C5.73478 19.5 5.48043 19.3946 5.29289 19.2071C5.10536 19.0196 5 18.7652 5 18.5V6.5C5 6.23478 5.10536 5.98043 5.29289 5.79289C5.48043 5.60536 5.73478 5.5 6 5.5H7V8.5C7 8.76522 7.10536 9.01957 7.29289 9.20711C7.48043 9.39464 7.73478 9.5 8 9.5H14C14.2652 9.5 14.5196 9.39464 14.7071 9.20711C14.8946 9.01957 15 8.76522 15 8.5V6.91L19 10.91V18.5Z"
                                          fill="currentColor"/>
                                </svg>
                            </rnd-btn>
                        </div>
                        <div :class="['modal-header']">
                            <span v-if="coin" :class="[`${colorClass}-bg`, 'coin']">{{coin.symbol}}</span>
                            <div class="modal-header_info">
                                <span>{{GET_RESULT_DATE}}</span>
                                <span># {{GET_RESULT_ID || ""}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="calcModal-content__modal-body-section">
                        <div class="calcModal-content__modal-body__options">
                            <t-select v-if="WIDGET_SYMBOLS" placeholder="Монета" :list="WIDGET_SYMBOLS" :selected-value="coin"
                                      text-value="symbol"
                                      @selected="onSelect('coin', $event)"/>
                            <t-select placeholder="Тип сделки" :list="GET_ORDER_TYPES" :selected-value="orderType"
                                      @selected="onSelect('orderType', $event)"/>
                            <t-text-field width="100%" label="Сумма закупа" v-model="amount"
                                          @input="showCalculator=false"/>
                            <t-text-field width="100%" label="Верхняя цена" v-model="upPrice"
                                          @input="showCalculator=false"/>
                            <t-text-field width="100%" label="Нижняя цена" v-model="lowPrice"
                                          @input="showCalculator=false"/>
                            <t-select placeholder="Кол-во ордеров" :list="GET_ORDERS" :selected-value="order"
                                      @selected="onSelect('order', $event)"/>
                            <t-select placeholder="Тип лесенки" :list="GET_STEPS" :selected-value="step"
                                      @selected="onSelect('step', $event)"/>
                        </div>
                        <div class="calcModal-content__modal-body_calculates">
                            <div class="calcModal-content__modal-body_calculates-results">
                                <transition name="fade" type="in-out">
                                    <table v-if="showCalculator && GET_RESULTS_OF_CALCULATION && GET_RESULTS_OF_CALCULATION.length > 0">
                                        <tr>
                                            <th :class="[`${colorClass}-bg`]">{{orderType.name}} шт.</th>
                                            <th :class="[`${colorClass}-bg`]">Цена</th>
                                            <th :class="[`${colorClass}-bg`]">Сумма</th>
                                        </tr>
                                        <template v-for="(line, index) of GET_RESULTS_OF_CALCULATION">
                                            <tr v-if="index<GET_RESULTS_OF_CALCULATION.length"
                                                :key="`order-${index}`">
                                                <td v-for="(td, ndx) in line" :key="index+td"
                                                    :class="[ndx.includes('value') ? colorClass : '']"
                                                >
                                                    {{ndx.includes("price")
                                                      ? `$${td.toLocaleString("ru-RU", {
                                                      useGrouping: true,
                                                      minimumFractionDigits: priceStep.value
                                                    })}`
                                                    : ndx.includes("value") ? `$${td.toLocaleString("ru-RU", {
                                                          useGrouping: true,
                                                          minimumFractionDigits:1, maximumFractionDigits:2
                                                        })}`
                                                    : `${td.toLocaleString("ru-RU", {
                                                      useGrouping: true,
                                                      minimumFractionDigits: quantityStep.value
                                                    })}`}}
                                                </td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <th v-for="(td, index) in GET_RESULT_PRICE"
                                                :key="index+td+'lowheader'"
                                                class="yellow-bg"
                                            >
                                              {{index.includes("price")
                                                ? `$${td.toLocaleString("ru-RU", {
                                                  useGrouping: true,
                                                  minimumFractionDigits: priceStep.value
                                                })}`
                                                : index.includes("value") ? `$${td.toLocaleString("ru-RU", {
                                                      useGrouping: true,
                                                      minimumFractionDigits:1, maximumFractionDigits:2
                                                    })}`
                                                : `${td.toLocaleString("ru-RU", {
                                                  useGrouping: true,
                                                  minimumFractionDigits: quantityStep.value
                                                })}`}}
                                            </th>
                                        </tr>
                                    </table>
                                </transition>
                            </div>
                            <div class="calcModal-content__modal-body_calculates-selects">
                                <t-select placeholder="Округление цены" :list="GET_PRICE_STEPS"
                                          :selected-value="priceStep"
                                          @selected="onSelect('price', $event)"/>
                                <t-select placeholder="Округление кол-ва" :list="GET_QUANTITY_STEPS"
                                          :selected-value="quantityStep"
                                          @selected="onSelect('quantity', $event)"/>
                            </div>
                        </div>
                    </div>
                    <div class="calcModal-content__modal-body_calculates-selects">
                        <t-btn width="100%" :disabled="showCalculator" @click="onCalculate()">Рассчитать</t-btn>
                    </div>
                </div>
            </div>
        </section>
    </modal-wrapper>
</template>

<script>
    import ModalWrapper from "@/components/modals/ModalWrapper";
    import TTextField from "@/components/Blocks/TTextField";
    import TSelect from "@/components/Blocks/TSelect";
    import TBtn from "../Blocks/TBtn";
    import {mapActions, mapGetters} from "vuex";
    import domtoimage from 'dom-to-image-more';
    import RndBtn from "../Blocks/RndBtn/RndBtn";
    import FinedField from "../Blocks/FindField";
    import CalcHistoryItem from "../Blocks/CalcHistoryItem/CalcHistoryItem";

    // <transition name="fade" type="in-out">
    //         <t-btn v-if="showCalculator" style="margin-top: 20px" width="100%" @click="clear()">Очистить</t-btn>
    //         </transition>

    export default {
        name: "Calc",
        components: {CalcHistoryItem, FinedField, RndBtn, TBtn, TSelect, TTextField, ModalWrapper},
        props: {
            modalName: String,
        },
        computed: {
            ...mapGetters([
                "GET_RESULT_PRICE",
                "GET_RESULT_DATE",
                "GET_RESULTS_OF_CALCULATION",
                "GET_ORDER",
                "GET_STEP",
                "GET_PRICE_STEP",
                "GET_QUANTITY_STEP",
                "GET_AMOUNT",
                "GET_UP_PRICE",
                "GET_LOW_PRICE",
                "GET_SHOW_CALCULATOR",
                "GET_COIN",
                "GET_ORDER_TYPE",
                "WIDGET_SYMBOLS",
                "GET_CALCULATES",
                "GET_RESULT_ID",
                "GET_ORDERS",
                "GET_ORDER_TYPES",
                "GET_STEPS",
                "GET_PRICE_STEPS",
                "GET_QUANTITY_STEPS",
                "GET_CALCULATES_PAGES_COUNT",
                "CAN_SAVE"
            ]),
            findSymbol() {
                return this.WIDGET_SYMBOLS.find()
            },
            order: {
                get() {
                    return this.GET_ORDER
                },
                set(value) {
                    this.SET_CALC_DATA({to: "order", data: value})
                }
            },
            step: {
                get() {
                    return this.GET_STEP
                },
                set(value) {
                    this.SET_CALC_DATA({to: "step", data: value})
                }
            },
            priceStep: {
                get() {
                    return this.GET_PRICE_STEP
                },
                set(value) {
                    this.SET_CALC_DATA({to: "priceStep", data: value})
                }
            },
            quantityStep: {
                get() {
                    return this.GET_QUANTITY_STEP
                },
                set(value) {
                    this.SET_CALC_DATA({to: "quantityStep", data: value})
                }
            },
            amount: {
                get() {
                    return this.GET_AMOUNT
                },
                set(value) {
                    this.SET_CALC_DATA({to: "amount", data: value})
                }
            },
            lowPrice: {
                get() {
                    return this.GET_LOW_PRICE
                },
                set(value) {
                    this.SET_CALC_DATA({to: "lowPrice", data: value})
                }
            },
            upPrice: {
                get() {
                    return this.GET_UP_PRICE
                },
                set(value) {
                    this.SET_CALC_DATA({to: "upPrice", data: value})
                }
            },
            showCalculator: {
                get() {
                    return this.GET_SHOW_CALCULATOR
                },
                set(value) {
                    this.SET_CALC_DATA({to: "showCalculator", data: value})
                }
            },
            coin: {
                get() {
                    return this.GET_COIN ? this.GET_COIN : this.WIDGET_SYMBOLS ? this.WIDGET_SYMBOLS[0] : null
                },
                set(value) {
                    this.SET_CALC_DATA({to: "coin", data: value})
                }
            },
            orderType: {
                get() {
                    return this.GET_ORDER_TYPE
                },
                set(value) {
                    this.SET_CALC_DATA({to: "orderType", data: value})
                }
            },
            colorClass() {
                switch (this.orderType.value) {
                    case "1" :
                        return "green";
                    case "2" :
                        return "red";
                    default :
                        return ""
                }
            },
            filteredHistory() {
                return this.GET_CALCULATES.filter(el => el.id.toString().includes(this.searching))
            }
        },
        data() {
            return {
                doingScreenshot: false,
                searching: "",
                currentPage: 1
            }
        },
        methods: {
            ...mapActions(["SET_CALC_DATA", "CALCULATE", "getWIDGET_SYMBOLS", "ASK_CALCULATES", "ASK_CALCULATE_BY_ID", "SAVE_CALCULATE"]),
            saveCalculate() {
                this.ASK_CALCULATES({clear: true})
                this.SAVE_CALCULATE({id: this.GET_RESULT_ID}).then(() => {
                    this.ASK_CALCULATES({page: 1})
                })
            },
            onScroll(event) {
                const domElement = event.target
                if (domElement.scrollTop + domElement.clientHeight >= domElement.scrollHeight) {
                    if (this.currentPage <= this.GET_CALCULATES_PAGES_COUNT) this.currentPage++
                    this.ASK_CALCULATES({page: this.currentPage})
                }
            },
            onCalculate() {
                if (!this.GET_COIN) {
                    this.coin = this.WIDGET_SYMBOLS[0]
                }

                this.CALCULATE()
                this.SET_CALC_DATA({to: "canSave", data: true})
                if (this.showCalculator) {
                    this.showCalculator = false
                    setTimeout(() => {
                        this.showCalculator = true
                    }, 100)
                } else {
                    this.showCalculator = true
                }
            },
            clear() {
                this.SET_CALC_DATA({to: "canSave", data: false})
                this.coin = this.WIDGET_SYMBOLS[0]
                this.orderType = this.GET_ORDER_TYPES[0]
                this.showCalculator = false
                this.order = this.GET_ORDERS[0]
                this.step = this.GET_STEPS[0]
                this.priceStep = this.GET_PRICE_STEPS[1]
                this.quantityStep = this.GET_QUANTITY_STEPS[1]
                this.amount = ""
                this.lowPrice = ""
                this.upPrice = ""
                this.SET_CALC_DATA({to: "results", data: {}})
            },
            onScreenShot() {
                this.doingScreenshot = true
                const node = this.$refs.calculator
                domtoimage.toJpeg(node, {quality: 0.9})
                    .then((dataUrl) => {
                        this.doingScreenshot = false
                        const link = document.createElement('a');
                        link.download = 'screenshot.jpeg';
                        link.href = dataUrl;
                        link.click();
                    })
            },
            onSelect(type, selected) {
                switch (type) {
                    case "orderType" :
                        this.orderType = selected
                        break;
                    case "coin" :
                        this.coin = selected
                        break;
                    case "order" :
                        this.order = selected
                        break;
                    case "step" :
                        this.step = selected
                        break;
                    case "price" :
                        this.priceStep = selected
                        break;
                    case "quantity" :
                        this.quantityStep = selected
                        break;
                }
                this.showCalculator = false
                this.SET_CALC_DATA({to: "results", data: {}})
                this.SET_CALC_DATA({to: "canSave", data: false})
            },
            openCalculate(id) {
                this.showCalculator = true
                this.ASK_CALCULATE_BY_ID({id: id})
                this.SET_CALC_DATA({to: "canSave", data: false})
            }
        },
        created() {
            this.getWIDGET_SYMBOLS()
            this.ASK_CALCULATES({page: this.currentPage})
        },
        beforeDestroy() {
            this.ASK_CALCULATES({clear: true})
        }
    }
</script>

<style lang="scss">
    .calcModal {
        padding: 50px 24px 30px 24px;
        display: flex;
        row-gap: 27px;
        flex-direction: column;

        &-content {
            display: flex;
            flex-direction: row;
            column-gap: 24px;

            &__history {
                min-width: 30%;
                display: flex;
                flex-direction: column;
                row-gap: 12px;

                &-search {
                    background-color: #292A31;
                    padding: 6px 0;
                    border-radius: 7px;
                }

                &-list {
                    display: flex;
                    flex-direction: column;
                    row-gap: 12px;
                    overflow-y: auto;
                    flex: 1 1 auto;
                    max-height: 520px;

                    &::-webkit-scrollbar {
                        width: 0;
                    }
                }
            }

            &__modal-body {
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                &-header {
                    display: flex;

                    & .modal-btn-group {
                        display: flex;
                        column-gap: 12px;
                        flex: 1 1 auto;
                    }

                    & .modal-header {
                        display: flex;
                        column-gap: 24px;
                        align-items: center;

                        & .coin {
                            padding: 4px;
                            position: relative;


                            &.red, &.green {
                                color: white;
                                z-index: 1;

                                &:after {
                                    content: "";
                                    position: absolute;
                                    opacity: .7;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: -1;
                                }
                            }

                            &.red {
                                &:after {
                                    background-color: $red-dark;
                                }
                            }

                            &.green {
                                &:after {
                                    background-color: $green;
                                }

                            }
                        }

                        &_info {
                            display: flex;
                            flex-direction: column;
                            font-size: 12px;
                            color: $white;
                        }
                    }
                }

                &-section {
                    display: flex;
                    column-gap: 24px;
                }

                &__options {
                    min-width: 150px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;

                    &-selects {
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;
                        row-gap: 20px;
                    }
                }

                &_calculates {
                    color: white;
                    display: flex;
                    flex: 1 1 auto;
                    row-gap: 24px;
                    flex-direction: column;

                    &-results {
                        display: flex;
                        flex-direction: column;
                        flex: 1 1 auto;

                        & table {
                            width: 100%;
                            border-collapse: collapse;

                            & tr {
                                font-size: 15px;

                                & th {
                                    width: 30%;
                                    padding: 3px 3px;
                                    text-align: left;
                                    font-weight: normal;
                                }

                                & td {
                                    width: 30%;
                                    padding: 5px 3px;
                                    transition: all 300ms;
                                }

                                &:nth-child(odd) {
                                    & td {
                                        background-color: $grayscale-middle;
                                    }
                                }

                                &:hover {
                                    & td {
                                        background-color: $grayscale-extra-light;
                                    }
                                }
                            }

                        }
                    }

                    &-selects {
                        display: flex;
                        column-gap: 24px;
                    }
                }
            }
        }

        & .modal-title {
            margin: 0;
            color: white;
        }


        & .red {
            color: $red-middle
        }

        & .green {
            color: $green;
        }
    }

    .vm--modal {
        overflow: unset;
    }
</style>
