<template>
    <div class="calc-history-item" @click="$emit('click', id)">
        <div class="calc-history-item__block">
            <div class="calc-history-item__block-id">#{{id}}</div>
            <div class="calc-history-item__block-date">{{stringDate}}</div>
        </div>
        <div :class="['calc-history-item__symbol', {'red-bg': transactionType==='Продажа'}, {'green-bg': transactionType==='Покупка'}]">
            <span>{{symbol.symbol}}</span>
        </div>
        <div class="calc-history-item__menu"></div>
    </div>
</template>

<script>
    import dateFormatter from "../../../formatters/dateFormatter";
    export default {
        name: "CalcHistoryItem",
        props: ["id", "date", "transactionType", "symbol"],
        computed: {
            stringDate() {
                return dateFormatter(this.date, false, false)
            },
        }
    }
</script>

<style lang="scss">
    .calc-history-item {
        display: flex;
        flex: 1 1 61px;
        column-gap: 8px;
        padding: 12px;
        border: 2px solid $grayscale-middle-light;

        background: $grayscale-middle;
        box-sizing: border-box;
        border-radius: 15px;
        max-height: 61px;
        align-items: center;
        cursor: pointer;
        transition: all 300ms;
        &:hover {
            background: $grayscale-middle-light;
        }
        &__block {
            font-size: 14px;
            line-height: 20px;
            &-id {
                color: $white;
                font-weight: 500;
                letter-spacing: 0.0027em;
            }
            &-date {
                color: $blue-middle;
                font-size: 14px;
            }
        }
        &__symbol {
            padding: 5px 10px;
        }
    }
</style>