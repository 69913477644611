<template>
    <modal-wrapper :modal-name="modalName">
        <section class="botSettingsModal">
            <h3 class="modal-title">Параметры бота</h3>
            <div class="switch-wrapper" v-if="state">
                <t-switch-btn
                        :switches="state"
                        :active="active" @click="switcher" active-color="#FFAB2D"/>
            </div>
            <div class="botSettingsModal_content_container">
                <div class="settings-config">
                    <template v-for="name in currentBotKeys">
                        <t-text-field
                                v-if="!name.includes('f_score') && !name.includes('signals_exclude_tf')"
                                :key="name"
                                width="180"
                                :class="[{longs: name.includes('long') && name.includes('point')}, {shorts: name.includes('short') && name.includes('point')}]"
                                v-model="currentBot[name]"
                                :label="name.replaceAll('_', ' ')"
                                :error="error[name.toLowerCase()]"
                                @blur="checkValue()"
                        />
                    </template>
                </div>

                <div v-if="currentBotTimeframes" class="settings-timeframes">
                    <chips
                            v-for="timeframe in ALLTIMEFRAMES"
                            :title="timeframe.nameRu"
                            :id="timeframe.name"
                            :key="timeframe.name"
                            :active="!currentBotTimeframes.includes(timeframe.name)"
                            @select="edit"
                    />
                </div>
                <div v-if="isScore" class="settings-config">
                    <template v-for="name in currentBotKeys">
                        <t-text-field
                                v-if="name.includes('f_score')"
                                :key="name"
                                width="180"
                                v-model="currentBot[name]"
                                :label="name.replace('f_score', '').replaceAll('_', ' ')"
                        />
                    </template>
                </div>
                <div v-if="currentBotBools.length > 0" class="settings-config">
                    <check-box
                            v-for="name in currentBotBools" :key="name"
                            v-model="currentBot[name]"
                            :value="true"
                            :label="name.replaceAll('_', ' ')"
                    />
                </div>
            </div>
            <div class="botSettingsModal_buttons">
                <t-btn width="240px" outlined @click="cancel">Отменить</t-btn>
                <t-btn width="240px" @click="save">Сохранить</t-btn>
            </div>
        </section>
    </modal-wrapper>
</template>


<script>
    import ModalWrapper from "./ModalWrapper";
    import TTextField from "../Blocks/TTextField";
    import Chips from "../Blocks/Chips/Chips";
    import {mapActions, mapGetters} from "vuex";
    import TBtn from "../Blocks/TBtn";
    import CheckBox from "@/components/Blocks/CheckBox";
    import TSwitchBtn from "../Blocks/TSwitchBtn";

    export default {
        name: "BotSettings",
        components: {TSwitchBtn, CheckBox, TBtn, Chips, TTextField, ModalWrapper},
        props: ["modalName"],
        computed: {
            ...mapGetters(["ALLTIMEFRAMES", "BOT_SETTINGS"]),

            isScore() {
              return this.currentBot ? Object.keys(this.currentBot).some(el => el.includes('f_score')) : false
            },
            currentBotKeys() {
                let obj = {...this.currentBot}
                delete obj.alias
                delete obj.id
                delete obj.name
                delete obj.title
                delete obj.value
                return Object.keys(obj).map(key => {
                    if (typeof obj[key] === "object") {
                        return ""
                    } else return key
                }).filter(key => key !== '')
            },
            currentBotBools() {
                let obj = {...this.currentBot}
                delete obj.alias
                delete obj.id
                delete obj.name
                delete obj.title
                delete obj.value
                return Object.keys(obj).map(key => {
                    return typeof obj[key]?.status === "boolean" ? key : ''
                }).filter(key => key !== '')
            },
            currentBotTimeframes() {
                return this.currentBot?.signals_exclude_tf?.split(', ')
            }
        },
        data: () => ({
            active: 1,
            state: [],
            currentBot: null,
            error: {
                leverage: false
            }
        }),
        methods: {
            ...mapActions(["getBOT_SETTINGS", "setBOT_SETTINGS", "getTIMEFRAMES"]),
            switcher(id) {
                this.active = id
                this.currentBot = {...this.BOT_SETTINGS.find(el => el.id === this.active)}
            },
            checkValue() {
                this.error.leverage = this.currentBot.Leverage < 1 || this.currentBot.Leverage > 20
            },
            edit(event) {
                let timeframes = this.currentBot.signals_exclude_tf.split(', ')
                if (timeframes.includes(event)) {
                    const ndx = timeframes.findIndex(el => el === event)
                    timeframes.splice(ndx, 1)
                    this.currentBot.signals_exclude_tf = timeframes.join(', ')
                    return
                }
                timeframes.push(event)
                this.currentBot.signals_exclude_tf = timeframes.join(', ')
            },
            cancel() {
                this.currentBot = {...this.state.find(el => el.value === this.active)}
                this.$modal.hide(this.modalName)
            },
            save() {
                let readyToSend = {...this.currentBot}
                Object.keys(readyToSend).forEach(key => {
                    if (this.currentBotBools.includes(key)) readyToSend[key] = this.currentBot[key].status
                })
                this.setBOT_SETTINGS({data: readyToSend, args: this.active})
            }
        },
        async created() {
            await this.getBOT_SETTINGS()
            this.state = [...this.BOT_SETTINGS]
            this.currentBot = {...this.BOT_SETTINGS.find(el => el.id === this.active)}
        }
    }
</script>

<style lang="scss">
    .botSettingsModal {
        padding: 50px 24px 30px 24px;
        display: flex;
        row-gap: 27px;
        flex-direction: column;
        overflow: hidden;

        & .modal-title {
            margin: 0;
            color: white;
        }

        &_content {
            &:before, &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
            }

            &:before {
                border-top: 1px solid #5B5C67;
            }

            &:after {
                border-bottom: 1px solid #5B5C67;
            }

            &_container {
                padding: 27px 0;
                display: flex;
                flex-direction: column;
                row-gap: 27px;

                & .settings-config, & .settings-timeframes {
                    display: flex;
                    column-gap: 24px;
                    row-gap: 24px;
                    flex-wrap: wrap;
                }

                & .settings-timeframes {
                    flex-wrap: wrap;
                    row-gap: 14px;
                    column-gap: 10px;
                }
            }
        }

        &_buttons {
            display: flex;
            column-gap: 24px;
            justify-content: center;
        }

        & .t-text-field__input-field__input-container__input:not(:placeholder-shown) ~ label {
          background-color: $grayscale-dark;
        }
    }

    .longs {
      & input, label {
        color: $green !important;
      }
    }
    .shorts {
      & input, label {
        color: $red-middle !important;
      }
    }
</style>
