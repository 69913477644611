<template>
  <label class="t-checkbox">
    <input class="t-checkbox__input" type="checkbox" :checked="shouldBeChecked" :value="value" @change="updateInput">
    <span class="t-checkbox__label subtitle-2" :style="label === '' ? {fontSize: '14px'} : ''">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "CheckBox",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    modelValue: {
      default: ""
    },
    label: {
      type: [String, Number],
      required: true
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    }
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      if (this.modelValue.constructor === Object) {
        return this.modelValue.status === this.value
      }
      return this.modelValue === this.value
    }
  },
  methods: {
    updateInput(event) {
        this.$emit('change', {status: event.target.checked, value: this.value})
    }
  }
}
</script>

<style lang="scss">
/* Dafault */
label.t-checkbox {
  cursor: pointer;
  user-select: none;
  position: relative;
  color: white;
  font-size: 14px;
  & .t-checkbox__input[type=checkbox] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
    & + .t-checkbox__label {
      position: relative;
      padding: 0 0 0 31px;
      &:before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 0;
        top: 2px;
        border: 1px solid $grayscale-light;
        box-sizing: border-box;
        background-color: $grayscale-dark;
        border-radius: 3px;
        transition: all 0.3s ease;
      }
      &:after {
        transition: all 0.3s ease;
      }
    }
    &:hover + .t-checkbox__label {
      &:before, &:after {
        transform: scale(1.28);
      }
    }
    &:checked {
      & + span {
        &:before {
          border: 1px solid $yellow-middle;
          background-color: $yellow-middle
        }
        &:after {
          content: url("../../assets/imgs/checked.svg");
          position: absolute;
          left: 3px;
          top: -2px;
        }
      }
    }
    &:disabled {
      & + span {}
    }
    &:disabled:checked {
      & + span {}
    }
  }
}
</style>
