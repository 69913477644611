<template>
  <div :class="['side-bar', {'closed' : isClosed}]">
    <div class="side-bar__control">
      <rnd-btn @click="openSideBar()">
        <svg v-if="isClosed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 19H3V17H21V19ZM21 13H8V11H21V13ZM21 7H3V5H21V7Z" fill="#5B5C67"/>
          <path d="M3 10L6 12L3 14V10Z" fill="#5B5C67"/>
        </svg>
        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 19H3V17H21V19ZM16 13H3V11H16V13ZM21 7H3V5H21V7Z" fill="#5B5C67"/>
          <path d="M21 10L18 12L21 14V10Z" fill="#5B5C67"/>
        </svg>
      </rnd-btn>
    </div>
    <div class="side-bar__content">
      <div class="side-bar__content__user-avatar">
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="44" height="44" rx="22" fill="#41424B"/>
          <path d="M21.9833 28C20.5278 28 19.3333 27.5462 18.4 26.6387C17.4667 25.7311 17 24.4594 17 22.8235V16H19.3833V22.9076C19.3833 23.8151 19.6167 24.5154 20.0833 25.0084C20.5611 25.4902 21.1944 25.7311 21.9833 25.7311C22.7833 25.7311 23.4167 25.4902 23.8833 25.0084C24.3611 24.5154 24.6 23.8151 24.6 22.9076V16H27V22.8235C27 24.4594 26.5278 25.7311 25.5833 26.6387C24.65 27.5462 23.45 28 21.9833 28Z" fill="#222329"/>
        </svg>
        <h5 :class="['side-bar__content__user-avatar_label']">{{user}}</h5>
      </div>
      <div class="side-bar__content__navigation">
        <template v-for="item in menu">
          <router-link
                  :key="item.path"
                  :to="item.path"
                  v-bind="$attrs"
                  custom
                  v-slot="{ href, navigate, isActive, isExactActive}"
                  :class="['side-bar__content__navigation-icons', {'disabled' : item.blocked}]"
                  append
                  exact
          >
            <a :href="href" :class="[isActive && 'active', isExactActive && 'active', {'hovering' : !isClosed}]" @click="navigate" @mouseup="sendTitle(item.label)">
              <rnd-btn v-if="isClosed" v-html="item.icon" :style="{fill: isActive ? item.fillColor : item.path === '/cabinet/dashboard' ? '#5B5C67' : '#1A1B20', color: isActive ? item.color : 'currentColor'}" />
              <div v-else :class="['icon']" v-html="item.icon" :style="{fill: isActive ? item.fillColor : item.path === '/cabinet/dashboard' ? '#5B5C67' : '#1A1B20', color: isActive ? item.color : ''}" />
              <span :class="[{'collapsed' : isClosed}, 'side-bar__content__navigation-icons_label', 'paragraph-2']">{{item.label}}</span>
            </a>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RndBtn from "../RndBtn/RndBtn";
export default {
  name: "SideBar",
  components: {RndBtn},
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    user: {
      type: String,
      default: "Admin"
    }
  },
  data() {
    return {
      isClosed: true
    }
  },
  methods: {
    sendTitle(title) {
      this.$emit('click', title)
    },
    openSideBar() {
      this.isClosed = !this.isClosed
    }
  }
}
</script>

<style scoped lang="scss">
.side-bar {
  height: 100vh;
  border-right: 1px solid $grayscale-middle-light;
  background-color: $grayscale-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 200ms ease-in-out;
  width: 240px;

  padding: 0 17px;
  box-sizing: border-box;
  &.closed {
    width: 78px;
  }
  &__control {
    margin: 32px 0 30px 0;
    cursor: pointer;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: left;
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: inherit;
    position: relative;
    &__user-avatar {
      margin-top: 18px;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      position: relative;
      left: 17px;
      & svg {
        min-width: 44px;
      }
      &_label {
        width: 100%;
        transition: all 250ms ease;
        overflow: hidden;
        color: $white;
        white-space: nowrap;
        padding: 0 17px;
        margin: 0;
        &.collapsed {
          width: 0;
          margin: 0;
          padding: 0;
        }
      }
    }
    &__navigation {
      padding: 0;
      &-icons {
        margin-top: 15px;
        cursor: pointer;
        transition: all .3s;
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;
        text-decoration: none;
        position: relative;
        left: 17px;
        z-index: 10;
        &:after {
          content: '';
          position: absolute;
          left: -3px;
          top: 0;
          bottom: 0;
          right: 33px;
          background-color: $grayscale-middle-light;
          border-radius: 24px;
          z-index: -1;
          transition: all 200ms;
          transform: scale(0, 1);
        ;
        }
        &:not(.active) {
          color: $grayscale-extra-light !important;
          & svg {
            fill: $grayscale-dark;
          }
        }
        & .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 44px;
          min-width: 44px;
        }
        &.disabled {
          color: $grayscale-extra-light;
          & svg {
            fill: $grayscale-dark;
          }
        }
        &_label {
          width: 100%;
          transition: all 200ms;
          overflow: hidden;
          color: $white;
          white-space: nowrap;
          padding: 0 14px;
          &.collapsed {
            width: 0;
            margin: 0;
            padding: 0;
          }
        }
        &.hovering:hover:after {
          transform: scale(1, 1);
        }
      }
    }
  }
}
</style>
