<template>
    <!--  <div class="t-text-field" :style="convertWidth">-->
    <div :style="convertWidth" class="t-text-field__input-field"
         :class="[{'active' : isFocus}, {'error' : error}, {'outlined' : outlined && !isSelectComponent}]">
        <div class="t-text-field__input-field__input-container">
            <input
                    v-bind="$attrs"
                    :value="value"
                    :class="['t-text-field__input-field__input-container__input', {'outlined' : outlined}]"
                    :type="type"
                    placeholder=" "
                    @input="$emit('input', $event.target.value)"
                    autocomplete="off"
                    @focus="isFocus=true"
                    @blur="onBlur($event)"
                    @click="$emit('click')"
            >
            <label class="capitalize"><span>{{label}}</span></label>
        </div>
        <div class="append-icon" @click="$emit('onAppend')">
            <slot name="append-icon"/>
        </div>
    </div>
    <!--  </div>-->
</template>
<script>
    export default {
        name: "TTextField",
        props: {
            value: {
                type: [String, Number],
                default: "",
            },
            type: {
                type: String,
                default: "text"
            },
            width: {
                type: String,
                default: "100%"
            },
            label: {
                type: String,
                default: '',
            },
            error: {
                type: Boolean,
                default: undefined
            },
            append: {
                type: String,
                default: undefined
            },
            outlined: {
                type: Boolean,
                default: true
            },
            isSelectComponent: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            convertWidth() {
                if (this.width.substr(-1) === "%" || this.width.substr(-1) === "x") {
                    return `width: ${this.width}`
                }
                return `width: ${this.width}px`
            }
        },
        data() {
            return {
                isFocus: false,
            }
        },
        methods: {
            onBlur(event) {
                this.isFocus = false
                this.$emit('blur', event)
            }
        }
    }
</script>

<style scoped lang="scss">
    .t-text-field__input-field {
        display: flex;
        box-sizing: border-box;
        border-radius: 8px;
        align-items: center;
        cursor: text;
        transition: all .3s;
        color: $grayscale-extra-light;

        &.outlined {
            border: 2px solid $grayscale-extra-light;
        }
        &[disabled].outlined {
          border: 2px solid rgba(91, 92, 103, 0.3);
        }
        &.active {

        }

        &__input-container {
            position: relative;
            transition: all .3s;
            flex: 1 1 auto;

            & input {
                font-family: Geometria, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                width: 100%;
                color: $white;
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active{
                    appearance: inherit;
                    background-image: none!important;
                    -webkit-box-shadow: 0 0 0 30px #222329 inset !important;
                    -webkit-text-fill-color: white !important;
                    box-sizing: border-box;
                    border-radius: 8px;
                }
            }

            &__input {
                border: none;
                box-sizing: border-box;
                position: relative;
                z-index: 1;
                background-color: transparent;

                &.outlined {
                    height: 40px;
                    padding: 12px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: $grayscale-extra-light;
                    z-index: -1;
                }
            }

            &__input ~ label {
                position: absolute;
                left: 12px;
                top: 10px;
                z-index: 0;
                color: $grayscale-extra-light;
                transition: 0.3s;
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                display: flex;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
            }

            &__input:focus-visible {
                outline: none;
            }

            &__input:focus ~ label {
                color: $white;
            }

            &__input:focus:not(:read-only) ~ label,
            &__input:not(:placeholder-shown) ~ label {
                top: -9px;
                left: 12px;
                font-weight: normal;
                font-size: 11px;
                line-height: 14px;
                transition: 0.3s;
                z-index: 10;
                padding: 0 4px;
                max-width: initial;
                background-color: $grayscale-middle;
                opacity: 1;
                color: $white;
            }

            &__input:focus {
                border-color: $white;
            }

            // if disabled
            &__input:disabled {
                & ~ label {
                    z-index: 1;
                }

                //&:not(:placeholder-shown) ~ label {
                //  &:after {
                //    content: "";
                //   position: absolute;
                //  top: 0;
                // left: 0;
                //  width: 100%;
                //  bottom: 12px;
                //  z-index: -1;
                //}
                //}
            }
        }

        & .append-icon {
            display: flex;
            align-items: center;

            & > svg,
            & > div,
            & > p {
                padding-left: 10px;
                padding-right: 10px;
                cursor: pointer;
            }
        }

        &:hover {
            & .t-text-field__input-field__input-container {
                & label {
                    color: $white+ab;
                }
            }
        }

        //  if error
        &.error {
            border: 1px solid $red-middle;
        }

        &.error &__input-container label {
            color: $red-middle;
        }

        &.is-list-show {
            & .t-text-field__input-field {
                &.outlined {
                    border-bottom-color: transparent;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
    label.capitalize span:first-letter {
        text-transform: uppercase;
    }
</style>
