<template>
  <div class="cabinet-page">
    <page-wrapper
        :menu="MENU"
        :filters="SYMBOLS"
        :page-title="TITLE"
        :loader="GET_LOADER"
        @openCalc="openCalc"
        @openSettings="openSettings"
    >
        <router-view/>
    </page-wrapper>
  </div>
</template>

<script>
import PageWrapper from "../components/Pages/PageWrapper";
import {mapActions, mapGetters} from "vuex";
import Calc from "@/components/modals/Calc";
import BotSettings from "@/components/modals/BotSettings";
export default {
  name: "Cabinet",
  components: {PageWrapper},
  computed: {
    ...mapGetters(['SYMBOLS', 'TITLE', "GET_LOADER", "MENU"])
  },
  methods: {
    ...mapActions(["SAVE_TITLE", "getTIMEFRAMES", "get_ALL_TIMEFRAMES", "get_GENERAL_TIMEFRAMES", "SET_LOADER", "getSYMBOLS"]),
    openCalc() {
      this.$modal.show(Calc,
          {modalName: "calc"},
          {draggable: true, name: 'calc', width: 840}
      )
    },
    openSettings() {
      this.$modal.show(BotSettings,
              {modalName: "settings"},
              {draggable: true, name: 'settings', width: 840}
      )
    },
  },
  async created() {
    this.SET_LOADER(true)
    await this.getTIMEFRAMES()
    await this.get_ALL_TIMEFRAMES()
    await this.get_GENERAL_TIMEFRAMES()
    await this.getSYMBOLS()
  },
  updated() {
    this.SAVE_TITLE(this.$route.path.split("/")[this.$route.path.split("/").length-1])
  }
}
</script>

<style lang="scss">

</style>
