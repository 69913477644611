<template>
  <div class="page-wrapper">
    <side-bar :menu="menu" @click="clickMenu" />
    <div class="page-wrapper-wrapper">
      <app-bar :title="pageTitle" :loader="loader" @openCalc="$emit('openCalc')" @openSettings="$emit('openSettings')" />
      <app-content>
        <slot />
      </app-content>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/Blocks/SideBar/SideBar";
import AppBar from "@/components/Blocks/AppBar/AppBar";
import AppContent from "@/components/Blocks/Content/Content";

export default {
  name: 'PageWrapper',
  components: {
    AppContent,
    AppBar,
    SideBar,
  },
  props: {
    pageTitle: {
      type: String,
      default: "Сигналы"
    },
    menu: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      required: false
    },
    loader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickMenu(title) {
      this.$emit('click', title)
    },
  }
}
</script>
<style lang="scss">
.page-wrapper {
  display: flex;
  &-wrapper {
    flex: 1 1 auto;
    height: 100vh;
    position: relative;
    display: flex;
    overflow: hidden;
  }
}
</style>
