<template>
    <div :class="['chips', {'active' : active}]" @click="$emit('select', id)">
        <span>{{title}}</span>
    </div>
</template>

<script>
    export default {
        name: "Chips",
        props: {
            title: String,
            id: [String, Number],
            active: Boolean
        }
    }
</script>

<style lang="scss" scoped>
    .chips {
        padding: 11px 20px;
        color: $white;
        background-color: $grayscale-light;
        display: flex;
        align-items: center;
        border-radius: 100px;
        cursor: pointer;
        transition: all 300ms;
        position: relative;
        z-index: 0;
        min-width: 90px;
        & span {
            text-transform: uppercase;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            letter-spacing: 0.0125em;
            line-height: 13px;
            text-align: center;
            width: 100%;
        }
        &:after {
            content: "";
            position: absolute;
            border-radius: 100px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            transition: all 300ms;
        }
        &:hover:after {
            background-color: $grayscale-extra-light;
        }
        &.active {
            color: $grayscale-dark;
            background-color: $yellow-dark;
            &:hover:after {
                background-color: $yellow-middle;
            }
        }
    }
</style>